#Home,
  .contain-present-gite,
  .contain-present-gite,
  .contain-present-room,
  .contain-present-gite,
  .contain-present-room {
    max-width: 750px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 115px;
    flex-direction: column;
}

.entete {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}

.content-star {
    display: flex;
    width: 35%;
    min-width: 140px;
    justify-content: space-between;
    margin-top: -17px;
}

.star_ico {
    color: #a56f29;
}

.contain-video {
    display: flex;
    width: 100%;
}

.video-present {
    display: flex;
    width: 100%;
    z-index: 0;
}

.presentation-main {
    padding: 20px 15px;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
    border: 3px solid #a56f29 !important;
    min-width: 100px !important;
    transition: width .25s ease-in-out;
}

.carousel .thumb {
    border: none !important;
    min-width: 100px;
}

.main-of {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 1000px) {
    .main-of {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 65vw;
    }

}