.container {
    width: 100%;
}

.logo-transparent {
    position: absolute;
    top: 0%;
    display: flex;
    width: 100%;
    z-index: -1;
    opacity: 0.3;
}

.navbar {
    width: 100%;
    box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
    position: fixed;
    z-index: 3;
}

.logoimg {
    display: flex;
    width: 4em;
    transition: opacity 2s ease;
    opacity: 1;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
}


.navbar .nav-container li {
    list-style: none;
    padding: 15px 0;
    margin: 0;
}

.select-btn {
    height: 4vh;
    max-height: 40px;
    min-width: 225px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: Arial;
    font-size: 2.5vh;
    font-weight: bold;
    padding: 6px 24px;
    text-decoration: none;
    box-shadow: 0px 1px 5px #474544;
    border-radius: 1em;
    background: #ffffff78;
    color: #173303;
}



.select-btn_ico {
    height: 4vh;
    max-height: 40px;
    min-width: 225px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: Arial;
    font-size: 2.5vh;
    font-weight: bold;
    padding: 6px 24px;
    text-decoration: none;
    box-shadow: 0px 1px 5px #474544;
    border-radius: 1em;
    background: #ffffff78;
    color: #173303;
}

.select-btn:hover,
.select-btn_ico:hover {
    box-shadow: 0px 1px 5px #474544;
    border-radius: 1em;
    background: #508354e0;
    color: #ffffff;
    transform: scale(1.1);
}
.select-btn:active,
.select-btn_ico:active {
    box-shadow: 0px 1px 5px #474544;
    border-radius: 1em;
    background: #508354e0;
    color: #ffffff;
    transform: scale(1);
}



.nav-container {
    display: block;
    position: relative;
    height: 80px;
    backdrop-filter: blur(7.5px);
    background: #ffffffcf;
    box-shadow: 0 0 15px #000;
    position: relative;
    z-index: 100;
}

.nav-container .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
}

.nav-container .hamburger-lines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 25px;
    left: 20px;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.nav-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: black;
}

.nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
}

.navbar .menu-items {
    padding-top: 2vh;
    backdrop-filter: blur(7.5px);
    background: #fffdfa;
    box-shadow: 0 0 15px #000;
    height: 100vh;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    display: flex;
    width: 100%;
    max-width: 450px;
}

.navbar .menu-items li {
    font-weight: 500;
    padding: 5px 0;
    width: 40vh;
    max-width: 350px;
    display: flex;
    justify-content: center;
}
.licence{
    font-size: 1.4vh;
}
.logo {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5rem;
    overflow: hidden;
    max-height: 60px;
}

.nav-container input[type="checkbox"]:checked~.menu-items {
    transform: translateX(0);
}

.nav-container input[type="checkbox"]:checked~.hamburger-lines .line1 {
    transform: rotate(45deg);
}

.nav-container input[type="checkbox"]:checked~.hamburger-lines .line2 {
    transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked~.hamburger-lines .line3 {
    transform: rotate(-45deg);
}
.nav-container input[type="checkbox"]:checked~.logo .logoimg {
    opacity: 0;
}




/* radio btn selection langue */
.select-language {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    position: relative;
    z-index: 12;
    min-height: 10vh;
}

.info_lang {
    padding: 0px;
    font-size: 3vh;
}

.contain-btn {
    display: flex;
    width: 100%;
    max-width: 300px;
    justify-content: space-around;
}



.button-lang {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 35px;
    background: transparent;
    box-shadow: inset 0 0 6px;
    border: none;
    border-radius: 8px;
}

.button-lang:hover {
    cursor: pointer;
    box-shadow: 0px 1px 5px #474544;
    border-radius: 1em;
    border: none;
    background: #508354e0;
    color: #ffffff;
    transform: scale(1.1);
}

.btn-active {
    box-shadow: 0px 1px 5px #474544;
    border-radius: 1em;
    border: none;
    background: #508354e0;
    color: #ffffff;
    transform: scale(1.1);
}

.btn-active:hover {
    box-shadow: 0px 1px 5px #474544;
    border-radius: 1em;
    border: none;
    background: #508354e0;
    color: #ffffff;
    transform: scale(1.1);
}

.content-groupe-nav_2 {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

}

.content-groupe-nav_2 li {
    width: 80%;
    max-width: 500px;
}



.ico-footer {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.f {
    display: flex;
    flex-direction: column;
}

.f svg {
    font-size: 3rem;
}

.f svg:nth-child(1) {
    color: #757575bf;
}

.licence {
    padding-bottom: 0;
}


.nav-g {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.select-btn svg, .select-btn_ico svg {
    background: #5d7b5678;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 20px;
    min-width: 20px;
    border-radius: 50%;
    position: relative;
    left: -25px;
    padding: 10px;
}
.name-select-btn{
    
    display: flex;
    width: 100%;
    justify-content: start;

}

@media (max-height: 830px) {
    .entete h1 {
        font-size: 2.3rem;
    }

    .navbar .menu-items {
        max-width: 380px;
    }

    .info-lang {
        font-size: 2.2rem;
    }


}



@media (min-width: 1000px) {

    .nav-container input[type="checkbox"]:checked~.menu-items {
        transform: translateY(0);
        opacity: 1;
    }

    .navbar .menu-items {
        padding-top: 0;
        backdrop-filter: blur(7.5px);
        background: #fffdfa;
        box-shadow: 0 0 15px #000;
        height: 80px;
        opacity: 0;
        flex-direction: row;
        transform: translateY(-150%);
        transition: transform 0.5s ease-in-out;
        transition: opacity 1.5s ease;
        display: flex;
        min-height: 100%;
        min-width: 100%;
        justify-content: space-between;
        align-items: flex-end;
    }

    .navbar .menu-items li {
        padding: 0;
        width: 100px;
    }

    .logo-transparent,
    .menu-items .entete,
    .menu-items .entete h1,
    .menu-items .entete .content-star,
    .menu-items .nav-group3,
    .menu-items .licence {
        display: none;
    }

    .button-lang {
        height: 20px;
    }

    .nav-group1 {
        display: flex;
        min-width: 520px;
        width: 100%;
        align-items: center;
        position: relative;
        top: -28px;
        left: 66px;
        justify-content: space-between;
    }

    .nav-g {
        flex-direction: row;
        justify-content: space-around;
        width: 56vw;
        max-width: 700px;
    }

    .nav-g li .select-btn {
        font-size: 0.8rem;
    }

    .select-btn, .select-btn_ico {
       height: 30px;
        min-width: 85px;
        padding: 4px;
        justify-content: start;
    }
    

    .nav-group2 {
        width: 49%;
        max-height: fit-content;
        position: relative;
        top: -17px;
        left: -24px;
    }

    .select-language {
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        justify-content: right;
        align-items: flex-end;
        max-width: 500px;
        margin-bottom: 15px;
    }

    .info_lang {
        padding: 0px;
        font-size: 0.7rem;
        min-width: fit-content;
    }

    .logoimg {
        display: flex;
        width: 4em;
        position: relative;
        z-index: 10000;
    }

    .contain-btn {
        display: flex;
        width: 100%;
        justify-content: space-around;
        max-width: 200px;
    }
    .name-select-btn{
        margin-left: -17px;
    }
    .select-btn svg, .select-btn_ico svg {
        min-height: 15px;
        min-width: 15px;
        border-radius: 50%;
        left: -19px;
    }
}

@media (min-width: 1200px){
    .nav-group2 {
        display: flex;
        width: 49%;
        justify-content: center;
        max-height: fit-content;
        position: relative;
        top: -17px;
        left: -20px;
    }
    .nav-group1 {
        align-items: center;
    }
}