.carousel-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
}

.carousel {
    display: flex;
    justify-content: center;
}

.contain-present-gite {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contain-list_option p {
    padding: 0;
    text-align: start;
    padding: 0 15px;
    font-size: 0.9rem;
    display: flex;
    width: 100%;
}

.contain-list_option {
    display: flex;
    width: 100%;
    flex-direction: column;
}

@media (min-width: 768px) and (max-width: 900px) {
    .contain-list_option p {
        font-size: 1.2rem;
    }

    .carousel {
        min-height: 120px;
        display: flex;
        align-items: center;
    }

    .carousel .thumb {
        min-width: 100px;
    }

    .thumbs {
        width: 100%;
        justify-content: space-around;
        min-height: 115px;
        display: flex;
        align-items: center;

    }

    .thumbs-wrapper {
        width: 100%;
        margin: 0 !important;
        height: min-content;
    }

    .carousel .control-dots .dot {
        width: 13px !important;
        height: 13px !important;
    }

    .carousel-status {

        font-size: 1.2rem !important;

    }

    .tarif-gite {
        margin-top: 50px;
    }

    .BulletsControl__BulletsControlContainer-sc-vxd05w-0 {
        width: 100%;
        display: flex;
        justify-content: center;

    }

    .Carousel__CarouselContainer-sc-f2ox7y-0 {
        gap: 100px !important;
    }

 

    .groupeinfo p {
        font-size: 1.4rem !important;
        padding: 15px 0 !important;
    }

}