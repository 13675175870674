.headTarif {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px;
}

.groupeinfo {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 190px;
    box-shadow: inset 3px 2px 7px #47454466;
    backdrop-filter: blur(7.5px);
    border-radius: 1em;
    margin: 8px 0;
}

.groupeinfo p {
    padding: 0;
    font-size: 1.2rem;
}

.info-logo {
    font-size: 1.5rem;
    padding-left: 15px;
    padding-right: 8px;
}

.headTarif p {
    padding: 0 15px;
    color: #3e3e3e;
    font-size: 1.1em;
}

.groupeinfo .textSubtitle {
    padding: 0 15px;
    color: brown;
    font-size: medium;
}

.headTarif h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0;

}

.tableTarif {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

#Price h2 {
    font-size: 2.5em;
}

.button-grille {
    box-shadow: 0px 1px 5px #474544;
    border-radius: 1em;
    background: #ffffff78;
    color: #173303;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    min-width: 250px;
    min-height: 32px;
    margin: 8px;
    justify-content: space-between;
    align-items: center;
    transition: color 0.4s ease;
}

.button-grille svg {
    font-size: 1.5em;
    padding-left: 8px;
    color: rgb(70 78 64);

}

.button-grille .btn_info {
    font-size: 1em;
    padding-right: 8px;

}

.button-grille:hover {

    box-shadow: 0px 1px 5px #474544;
    border-radius: 1em;
    background: #508354e0;
    color: #ffffff;
    transform: scale(1.1);
}

.button-grille:active {

    box-shadow: 0px 1px 5px #474544;
    border-radius: 1em;
    background: #508354e0;
    color: #ffffff;
    transform: scale(1);
}

#modalTarifGroup,
#modal-tarif-2 {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background: #d3d3d3f2;
    overflow-y: scroll;
    display: none;
}

#table-tarif-2p thread tr th,
#table-tarif-2p .table-price {
    min-width: 80px;
}

#table-tarif-2p tbody tr {
    min-height: 20px;
}

.card-modal-tarifs {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.tableColumn {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-height: 100%;
    justify-content: center;
    align-items: center;

}

.tableColumn span {
    font-size: 0.6rem;
    color: #0000008f;
}

.tableColumn,
th {
    box-shadow: inset 2px 2px 8px #71717173;
    background: #cfffcf2b;
    color: #4e744e;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 8px;
    border-radius: 0.8em;
}

td {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
    font-style: italic;
    background: #ffffff6e;
    box-shadow: inset 2px 2px 8px #7171719e;
    color: #536d53;
    border-radius: 0.8em;
}

.display-info-tarif {
    font-family: "Fondamento", serif;
    font-weight: 400;
    font-style: italic;
    color: #9d6803;
    font-size: 1.4em;
    padding: 5px 0 0 5px;
}

.liGite {
    font-size: 0.9em;
    padding: 0 5px;
}

.placeBottom {
    margin-bottom: 50px;
}

.star_ico-close-modal {
    font-size: 1.6em;
    position: fixed;
    z-index: 9000000;
    cursor: pointer;
    top: 10px;
    right: 5%;
    padding: 5px;
    background: #ff00006b;
    color: #d1d0d0;
    border-radius: 50%;
    min-width: 25px;
    min-height: 25px;
    box-shadow: 2px 2px 8px #0000005c;
}

#Price {
    box-shadow: 0px 1px 11px #474544;
    backdrop-filter: blur(7.5px);
    background: #ffffffcf;
    border-radius: 1em;
    width: 100%;
    max-width: 500px;
}

#modalTarifGroup .card-modal-tarifs .igcontent .displayInfo {

    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;

}

#modal-tarif-2 .card-modal-tarifs .igcontent .displayInfo {

    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;

}

#modalTarifGroup .card-modal-tarifs .table-Tarif {
    padding: 20px;
}

#modal-tarif-2 .card-modal-tarifs .table-Tarif {
    padding: 20px;
}

.groupeinfo p{
    font-weight: 700;
}
@media (min-width: 1000px) {
    .headTarif p {
        padding: 0 15px;
        color: #3e3e3e;
        font-size: 0.8em;
    }
    #Price h2 {
        font-size: 1.5em;
        padding: 0;
        margin: 0;
        margin-bottom: 5px;
        text-align: center;
    }
    .tableTarif .button-grille {
        margin: 0;
    }

    .groupeinfo {
        padding-top: 0px;
    }

    .group-tarif {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }
}