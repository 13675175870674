#Review{
    box-shadow: 3px 3px 6px 2px rgb(0 0 0 / 58%);
    backdrop-filter: blur(7.5px);
    background: #ffffff2f;
    border-radius: 1em;
    margin: 60px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    max-width: 500px;
}
.group-avis {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
.google-avis, .airbnb-avis{
    display: flex;
    flex-direction: column;
    width: 90%;
    min-width: 360px;
}
#Review h3{
    margin-bottom: -25px;
    margin-top: -20px;
}
.group-avis .google-avis h3:nth-child(1){
    color: rgb(130, 174, 212);
}
.group-avis .airbnb-avis h3:nth-child(1){
    color: rgb(199, 120, 120);
}
.sk_branding{
    display: none!important;
}
.content-avis {
    display: flex;
    width: 100%;
    height: 440px;
    justify-content: center;
    overflow: hidden;
    margin: 5px 0 30px 0;
}
.content-avis:nth-child(5) {
    margin: 5px 0 0 0;
}
.iframe-review{
    border: none;
    min-width: 100%;
    transform: scale(0.9);
    overflow: hidden;
    box-shadow: inset 3px 2px 7px #47454466;
    backdrop-filter: blur(7.5px);
    border-radius: 1em;
    padding: 10px;
}
body .sk-ww-google-reviews .sk-badge{
    padding: 0!important;
}
.sk-ww-google-reviews{
    padding: 0!important;
}
.tutorial_link{
    display: none!important;
}
.sk-ww-google-reviews{
    min-width: 100vw!important;
}



@media (min-width: 1000px){
    .iframe-review {
        transform: scale(0.9);
    }
    .google-avis, .airbnb-avis{
        width: 49%;
        min-width: 0;
    }

}