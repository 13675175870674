@import url("./style/Navbar.css");
@import url("./style/Main.css");
@import url("./style/giteEtChambre.css");
@import url("./style/allPrice.css");
@import url("./style/avis.css");
@import url("./style/formBooking.css");
@import url('https://fonts.googleapis.com/css2?family=Fondamento:ital@0;1&family=Spectral+SC:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');


#root {
  display: flex;
  width: 100%;
  flex-direction: column;
}

body {
  background: rgb(252, 238, 211);
  display: flex;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {

  font-family: "Spectral SC", serif;
  font-weight: 400;
  font-style: normal;
  color: #5A7745;
  font-size: 2.5em;
  padding-bottom: 5px;
}

h2 {
  font-family: "Spectral SC", serif;
  font-weight: 400;
  font-style: normal;
  color: #5A7745;
  font-size: 2em;
  padding-top: 25px;
  padding-bottom: 10px;
  text-align: center;
}

h3 {
  font-family: "Fondamento", serif;
  font-weight: 400;
  font-style: italic;
  color: #9d6803;
  font-size: 1.6em;
  padding: 10px 0 0 15px;
}

p {
  font-family: "Titillium Web", serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1rem;
  text-align: center;
}

@media (min-width: 1000px) {
  .video-present {
    box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.8);
    border-radius: 2em;
  }
  #Home,
  .contain-present-gite,
  .contain-present-gite,
  .contain-present-room,
  .contain-present-gite,
  .contain-present-room {
    width: 90%;
  }

  #Home {
    padding-top: 140px;
  }

  #Review {
    max-width: 90%;
  }




  #Booking {
    position: fixed;
    bottom: 20px;
    right: 5px;
    width: 350px;
    margin: 0;
  }

  #Price {
    position: fixed;
    top: 90px;
    right: 5px;
  }

  .btn-next {
    font-size: 1rem;
    padding: 3px;
  }
  #table-tarif-2p,
  #modalTarifGroup {
    min-height: 300px;
  }
  
  .table-Tarif {
  
    min-height: 336px;
  
  }

}


@media (min-width: 1250px) {
  h1 {
    font-size: 3.5em;
  }
  p{
    font-size: 1.2rem;
  }
  h3{
    font-size: 2em;
  }
  .contain-list_option p {
    font-size: 1.1rem;
  }
  input[type='text'], 
  [type='email'], 
  [type='tel'], 
  [type='street-address'], 
  [type='postal-code'], 
  [type='city'], 
  [type='date'], 
  select, 
  textarea {
    font-size: 1rem;
  }
  .select-btn, .select-btn_ico {
    height: 40px;
    min-width: 130px;
    padding: 5px;
    justify-content: start;
    border-radius: 2em;
  }
  .select-btn:hover, .select-btn_ico:hover {
    border-radius: 2em;
  }
  .select-btn svg, .select-btn_ico svg {
    min-height: 20px;
    min-width: 20px;
    border-radius: 50%;
    left: -15px;
  }
  .name-select-btn {
    margin-left: -5px;
  }
  .nav-g {
    width: 100%;
    max-width: 800px;
  }
  .info_lang {
    padding: 0px;
    font-size: 1rem;
    min-width: fit-content;
  }
  .select-language {
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    max-height: 90px;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }
  .nav-group2 {
    width: 65%;
    justify-content: center;
    max-height: 100%;
    left: 10px;
    top: -7px;
    position: relative;
  }
  .content-form {
    height: 350px;
    width: 100%;
  }
  .content-page-form {
    height: 330px;
  }
  .nav-g {
    width: 100%;
    max-width: 800px;
    position: relative;
    top: 7px;
  }
  #Booking {
    right: 1vw;
    top: 90px;
    width: 23%;
    max-width: none;
  }

  #Price {
    left: 1vw;
    top: 90px;
    width: 23%;
    max-width: none;
  }
  #Price h2 {
    font-size: 2em;
  }
  .headTarif p {
    font-size: 1em;
  }
  #Review {
    max-width: 50%;
  }

  .main-of {
    width: 100%;
  }

  #Home,
  .contain-present-gite,
  .contain-present-gite,
  .contain-present-room,
  .contain-present-gite,
  .contain-present-room {
    width: 50%;
    padding-top: 90px;
    max-width: none;
  }

}