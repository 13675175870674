input[type='text'],
[type='email'],
[type='tel'],
[type='street-address'],
[type='postal-code'],
[type='city'],
[type='date'],
select,
textarea {
    background: none;
    border: none;
    border-bottom: solid 2px #474544;
    color: #474544;
    font-size: 1.000em;
    font-weight: 400;
    letter-spacing: 1px;
    padding: 0.500em;
    text-transform: uppercase;
    width: 80%;
    box-sizing: border-box;
    transition: all 0.3s;
}


textarea {
    background: #c2e1ab4a;
    border: none;
    box-shadow: 1px 4px 11px #546f54eb;
    border-radius: 0.5em;
    display: flex;
    width: 100%;
    min-height: 150px;
    resize: none;
    padding: 10px 5px;
}

.group-card-num-nationality {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

[type='date'] {
    justify-content: center;
    font-size: 1.2rem;
    width: auto;
}

.inputRange {
    width: 90%;
    height: 7px;
}

.section-form {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 100px 0;
}

.content-form,
#Price {
    display: flex;
    flex-direction: column;
    box-shadow: 3px 3px 6px 2px rgb(0 0 0 / 58%);
    backdrop-filter: blur(7.5px);
    background: #ffffff2f;
    border-radius: 1em;
    width: 100%;
    align-items: center;
    justify-content: center;
    max-width: 500px;
}

.content-form-info {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    height: 100%;
    max-height: 300px;
    width: 100%;
}

.form-info {
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

#form-info-three {
    justify-content: center;
}

.btn-next {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 10px;
    margin-bottom: 15px;
    min-width: 150px;
    box-shadow: inset 0px 1px 11px #474544;
    border-radius: 1em;
    background: #bdbdbd4a;
    color: cornflowerblue;
}

.btn-next_active {
    background: #b3db95;
    color: #1e291a;
}

.btn-next {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 10px;
    margin-bottom: 15px;
    min-width: 150px;
    box-shadow: inset 0px 1px 5px #474544;
    border-radius: 1em;
    background: #8180804a;
    color: #919191;
    transition: background 1s ease-in;
}

.card-nbpeople {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 70px;
}

.card-nbpeople p {
    font-size: 1.3rem;
}

.group-card-tarif {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.group-card-choice {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 15px;
}

.group-card-choice label {
    color: #494949;
    font-size: 1.2rem;
    font-weight: 700;
}


.card-choice-booking {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
    position: relative;
    border: none;
    box-shadow: 3px 2px 8px;
    border-radius: 1em;
    padding: 10px 0;
    margin: 10px 0;
    justify-content: center;
    align-items: center;
}


input[type=radio] {
    --s: 1em;
    --c: #008309;
    position: absolute;
    opacity: 0.3;
    height: 100%;
    width: 100%;
    aspect-ratio: 1;
    border: calc(var(--s) / 8) solid #939393;
    padding: calc(var(--s) / 8);
    background: radial-gradient(farthest-side, var(--c) 94%, #0000) 50% / 0 0 no-repeat content-box;
    outline-offset: calc(var(--s) / 10);
    appearance: none;
    font-size: inherit;
    transition: .7s;
    cursor: pointer;
    padding: 1% 85% 10% 0;
}

input[type=radio]:checked {
    border-color: none;
    background-size: 100% 100%;
}

input[type=radio]:disabled {
    background:
        linear-gradient(#939393 0 0) 50%/100% 20% no-repeat content-box;
    opacity: .5;
    cursor: not-allowed;
}

.img-group-people {
    display: flex;
    height: 12em;
    position: absolute;
    z-index: -5;
    opacity: 0.09;
}

.card-nbpeople span {
    font-size: 1.5rem;
    font-weight: 700;
}

input[type="range"] {
    size: 2em;
}

#form-info-three .btn-next {
    margin-top: 30px;
}

.group-card-date {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.group-card-date p {
    font-size: 1.2rem;
    padding: 10px 0;
}

.card-date {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 10px 0;
}

.card-date input {
    display: flex;
    min-height: 30px;
}

.card-date span {
    font-size: 1.2rem;
    color: #a56f29;
    font-weight: bold;
}

.BTNREADY {
    box-shadow: 0px 1px 5px #474544;
    border-radius: 1em;
    background: #508354e0;
    color: #ffffff;
    cursor: pointer;
}

.form-info-autre select {
    font-size: 0.8rem;
}

.content-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.alert-input {
    display: flex;
    width: 80%;
    color: red;
    height: fit-content;
}

input[type='text'].inputnovalid,
.inputnovalid {
    background: #ff00001f;
    transition: background 1 ease-in;
}

input[type='text'].inputvalid,
.inputvalid {
    background: #00ff151f;
    transition: background 1 ease-in;
}

.content-fixe-valid {
    display: flex;
    width: 100%;
    height: 140px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.circle-anime.valid-circle {
    display: flex;
    width: 90px;
    height: 90px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    animation: turnInfinite infinite 2s cubic-bezier(1, .11, 0, .97);
    transition: box-shadow, background 1s;

}

.circle-anime.novalid-circle {
    display: flex;
    width: 90px;
    height: 90px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    animation: turnInfiniteRed infinite 0.5s ease-in-out;
    transition: box-shadow, background 1s;

}

#info-finish-valid {
    display: flex;
    margin: 15px 0;
}

.ico-valid {
    font-size: 5em;
    color: white;
}

.entete-valid-booking {
    margin: 0;
    padding: 10px 0;
    text-align: center;
}

.entete-no-valid-booking {
    margin: 0;
    padding: 10px 0;
    text-align: center;
    color: #ff0000bd;
}

#btn-next-valid {
    max-width: 150px;
}

#form-info-valid {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
}

#form-info-novalid {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
}

.group-info-name-client {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    max-height: 200px;
}

.content-page-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 390px;
    align-items: center;
}

.img-group-people2 {
    position: absolute;
    max-height: 80%;
    left: 20px;
}

@keyframes turnInfinite {
    0% {
        background: #4c64198c;
        transform: scale(0.7);
    }

    50% {
        background: #4fa016ab;
        transform: scale(1.3);
    }

    100% {
        transform: scale(0.7);
        background: #116e1d7e;
    }
}

@keyframes turnInfiniteRed {
    0% {
        background: #b419198c;
        transform: rotate(90deg);
    }

    50% {
        background: #a01616ab;
        transform: rotate(-90deg);
    }

    100% {
        transform: rotate(90deg);
        background: #a510107e;
    }
}








@media (min-width: 1000px) {

    input[type='text'],
    [type='email'],
    [type='tel'],
    [type='street-address'],
    [type='postal-code'],
    [type='city'],
    [type='date'],
    select,
    textarea {
        font-size: 0.7rem;
    }

    .content-form,
    #Price {
        height: 300px;
        width: 350px;
    }

    #Price {
        height: fit-content;
        width: 350px;
    }
    .card-choice-booking {
        max-height: 70px;
    }
    .content-page-form h2 {
        font-size: 2em;
        padding: 0;
        margin: 0;
        margin-bottom: 5px;
        text-align: center;
    }

    .headTarif p {
        padding: 0px 5px;
    }

    .content-page-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 300px;
        align-items: center;
    }

    .form-info {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .group-card-choice label {
        color: #494949;
        font-size: 0.9rem;
        font-weight: 700;
    }
    .group-card-choice p {
        font-size: 0.9rem;
    }
    .card-date {
        margin: 0;
    }

    .group-card-date {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: space-evenly;
        height: 100%;
    }

    .group-card-date p {
        font-size: 1rem;
        padding: 0;
    }

    .group-card-num-nationality {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .group-info-name-client {

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        width: 100%;
        max-height: 200px;

    }

    .circle-anime.valid-circle {
        display: flex;
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        animation: turnInfinite infinite 2s cubic-bezier(1, .11, 0, .97);
        transition: box-shadow, background 1s;

    }

    .circle-anime.novalid-circle {
        display: flex;
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        animation: turnInfiniteRed infinite 0.5s ease-in-out;
        transition: box-shadow, background 1s;

    }

    .ico-valid {
        font-size: 3em;
        color: white;
    }

}